import { ApolloQueryResult, FetchResult, QueryResult } from '@apollo/client';
import { AxiosPromise } from 'axios';

export type FinancialEventsContextType = {
  getFinancialEventsHandler: () => Promise<
    QueryResult<
      FinancialEvent,
      {
        input: {
          page: number;
          term: string | undefined;
          referencePeriod: Date | undefined;
          commercialGroupId: string | undefined;
          type: FinancialEventType | undefined;
          status: FinancialEventStatus | undefined;
        };
      }
    >
  >;
  getFinancialEventsBatchHandler(): Promise<
    QueryResult<
      FinancialEventsBatchGraphQLData,
      {
        financialEventsBatchId: string | undefined;
      }
    >
  >;
  getFinancialEventsBatchForcedHandler: () => Promise<
    QueryResult<
      FinancialEventsBatchGraphQLData,
      {
        financialEventsBatchId: string | undefined;
      }
    >
  >;
  handleSendFinancialEventsByBatchId: (
    id: string,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  handleSendFinancialEventsByIdsList: (
    idsList: string[],
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  refetchGetFinancialEventsBatchHandler: () => Promise<ApolloQueryResult<any>>;
  createFinancialEventsBatchHandler: (financialEvent: IFinancialEventsBatchPayload) => AxiosPromise<any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTermFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFinancialEventsBatchId: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateFinancialEventAttachmentHandler(financialEventId: string, attachmentId?: string): Promise<FetchResult<any>>;
  updateFinancialEventTargetUsersHandler(id: string): Promise<FetchResult<any>>;
  totalFinancialEvents: number | undefined;
  page: number;
  financialEvents?: Array<FinancialEvent>;
  financialEventsBatch?: FinancialEventsBatch;
  financialEventsBatchId: string | undefined;
  loading: boolean;
  sendFinancialEventsStatus: IFinancialEventsSendingStatus | undefined;
  openNotificationFeedBack: boolean;
  setOpenNotificationFeedBack: (value: boolean) => void;
  referencePeriodFilter: Date | undefined;
  setReferencePeriodFilter: React.Dispatch<React.SetStateAction<Date | undefined>>;
  commercialGroupIdFilter: string | undefined;
  setCommercialGroupIdFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  typeFilter: FinancialEventType | undefined;
  setTypeFilter: React.Dispatch<React.SetStateAction<FinancialEventType | undefined>>;
  setStatusFilter: React.Dispatch<React.SetStateAction<FinancialEventStatus | undefined>>;
};

export type IFinancialEventsSendingStatus = 'SENDING' | 'SENDING_FAILED' | 'SENDING_SUCCESS';
export interface FinancialEventsGraphQLData {
  getFinancialEvents: {
    page: number;
    total: number;
    limit: number;
    data: Array<FinancialEvent>;
  };
}

export interface FinancialEventsBatchGraphQLData {
  getFinancialEventsBatch: FinancialEventsBatch;
}

export interface SendFinancialEventsByBatchIDGraphQLData {
  sendFinancialEventsByBatchID: boolean;
}

export interface SendFinancialEventsByIDsListGraphQLData {
  sendFinancialEventsByIDsList: boolean;
}

export interface UpdateFinancialEventAttachmentGraphQLData {
  updateFinancialEventAttachment: boolean;
}

export interface FinancialEvent {
  agent?: string;
  amountToPay?: number;
  attachment?: FinancialEventAttachment;
  batchId?: string;
  commercialGroup?: FinancialEventCommercialGroup;
  createdAt?: string;
  eerFeePerProfiles?: Record<string, number>;
  emailContent?: string;
  id?: string;
  liquidationFee?: number;
  liquidationGuarantee?: number;
  paymentDeadline?: string;
  referencePeriod?: string;
  sentOn?: string;
  status?: FinancialEventStatus;
  targetUsers?: FinancialEventTargetUsers[];
  type?: FinancialEventType;
}

export interface FinancialEventTargetUsers {
  id?: string;
  name?: string;
  email?: string;
}

export interface FinancialEventsBatch {
  attachments?: Array<FinancialEventAttachment>;
  createdAt?: string;
  dataSourceFileUrl?: string;
  financialEvents?: Array<FinancialEvent>;
  id: string;
  paymentDeadline?: string;
  processingPercentage: number;
  rawData?: JSON;
  referencePeriod?: string;
  status: FinancialEventsBatchStatus;
  type?: FinancialEventType;
}

export interface FinancialEventAttachment {
  batchId: string;
  createdAt: string;
  filename: string;
  id: string;
  thirdPartyName: string;
  url: string;
}
export interface FinancialEventAttachmentOption extends FinancialEventAttachment {
  isRelationed?: boolean;
}

export enum FinancialEventType {
  FINANCIAL_GUARANTEE = 'FINANCIAL_GUARANTEE',
  FINANCIAL_SETTLEMENT = 'FINANCIAL_SETTLEMENT',
  ASSOCIATIVE_CONTRIBUTION = 'ASSOCIATIVE_CONTRIBUTION',
  RESERVE_ENERGY_CHARGE = 'RESERVE_ENERGY_CHARGE',
  POWER_CHARGE_CAPACITY_RESERVE = 'POWER_CHARGE_CAPACITY_RESERVE',
}

export enum FinancialEventStatus {
  UNPROCESSED = 'UNPROCESSED',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  SENDING_FAILED = 'SENDING_FAILED',
}

export enum FinancialEventsBatchStatus {
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  UNPROCESSED = 'UNPROCESSED',
  PROCESSING = 'PROCESSING',
  PROCESSED_WITH_ERROR = 'PROCESSED_WITH_ERROR',
}

export interface FinancialEventsFilters {
  group?: FinancialEventCommercialGroup;
  month?: number;
  year?: number;
  searchTerm?: string;
  status?: FinancialEventStatus;
}

export interface FinancialEventCommercialGroup {
  id: string;
  name: string;
  cnpj: string;
}

export interface IFinancialEventsBatchPayload {
  type?: FinancialEventType;
  referencePeriod?: string;
  paymentDeadline?: string;
  spreadsheetToUpload?: File;
  attachmentsToUpload?: Array<File>;
}
