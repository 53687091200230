import { gql } from '@apollo/client';

export const GET_ENERGY_CONTRACTS_QUERY = gql`
  query EnergyContracts($input: GetEnergyContractsInput) {
    energyContracts(input: $input) {
      total
      page
      limit
      data {
        id
        gmvAmount
        carbonDioxideTonAmount
        takeRateAmount
        isFixedContractedVolume
        contractedVolumePerYear {
          volumeAmount
          year
        }
        units {
          id
          group {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ENERGY_CONTRACT_BY_ID_QUERY = gql`
  query EnergyContract($energyContractId: ID!) {
    energyContract(id: $energyContractId) {
      id
      idCceeTrader
      perfilCceeTrader
      group {
        id
      }
      profilesCcee {
        id
      }
      lowerFlexibility
      upperFlexibility
      units {
        id
        group {
          id
        }
      }
      contractType
      startSupplyDate
      endSupplyDate
      energyType
      coverCceeTaxes
      coverCceeAmount
      coverMeterSystem
      meterSystemAmount
      payDayType
      payDay
      guaranteeType
      otherGuaranteeType
      guaranteeMonths
      cceeAgent
      proposalId
      economy {
        year
        amount
      }
      trader {
        id
        type
      }
      isFixedContractedVolume
      includeCceeCharges
      contractedVolumePerYear {
        year
        volumeAmount
        month
      }
      hasSazonality
      commissionModel
      advanceCommission
      losts
      readjustmentIndex
      readjustmentFrequency
      readjustmentBaseDate
      energyContractCommissions {
        id
        commissionAmount
        commissionType
        year
      }
      energyContractAttachments {
        id
        energyContractId
        thirdPartyName
        url
        filename
        fileSize
        createdAt
        updatedAt
      }
    }
  }
`;

const BIDS_FIELDS = gql`
  fragment BidsFields on Bid {
    payDay
    status
    retailService
    trader {
      id
      name
    }
    economy {
      period
      economyPerYear {
        year
        amount
      }
    }
    coverCceeTaxes
    coverMeterSystem
    meterSystemAmount
  }
`;

const SUPPLY_FIELDS = gql`
  fragment SupplyFields on Supply {
    startDate
    endDate
    unit {
      id
      name
    }
  }
`;

export const GET_PROPOSALS_QUERY = gql`
  ${BIDS_FIELDS}
  ${SUPPLY_FIELDS}
  query GetProposals($page: Int) {
    proposals(page: $page) {
      total
      limit
      data {
        id
        group {
          id
          name
        }
        contractType
        guaranteeMonths
        guaranteeType
        otherGuarantee
        lowerFlexibility
        upperFlexibility
        status
        round
        bids {
          ...BidsFields
        }
        supply {
          ...SupplyFields
        }
        createdAt
      }
    }
  }
`;

export const GET_GROUPS_DATA_QUERY = gql`
  query GetAllCommercialGroups {
    groups {
      data {
        id
        name
        units {
          id
          name
        }
      }
    }
  }
`;
