import React from 'react';
import { Controller, FieldPath, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { RadioButton } from '@clarke-energia/foton';

export interface RadioFormFieldProps<T extends FieldValues> {
  field: FieldPath<T>;
  label: string;
  inputOptions: { id: string; optionLabel: string; value: string; defaultChecked?: boolean }[];
  options?: RegisterOptions<T, FieldPath<T>>;
  valueAsBoolean?: boolean;
  direction?: 'row' | 'column';
}

export function RadioFormField<T extends FieldValues>({
  field,
  label,
  inputOptions,
  options,
  valueAsBoolean,
  direction = 'column',
}: RadioFormFieldProps<T>) {
  const { control, setValue } = useFormContext<T>();
  return (
    <div>
      <label className="text-paragraph-medium">
        {label}
        {options?.required && <span className="text-danger-60">*</span>}
      </label>
      <div className={`flex ${direction === 'row' ? 'flex-row space-x-3 mt-[10px]' : 'flex-col space-y-3'}`}>
        {inputOptions.map(({ id, value, optionLabel, defaultChecked }) => (
          <Controller
            key={id}
            control={control}
            name={field}
            rules={options}
            render={({ field: { name, onBlur } }) => (
              <RadioButton
                id={id}
                name={name}
                label={optionLabel}
                value={value}
                defaultChecked={defaultChecked}
                required={(options?.required as boolean) || false}
                onBlur={onBlur}
                onChange={(e) => {
                  const newValue = valueAsBoolean ? (e.target.value == 'true' ? true : false) : e.target.value;
                  //@ts-expect-error -> dynamic type of field
                  setValue(field, newValue);
                  if (options?.onChange) {
                    options?.onChange(newValue);
                  }
                }}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
}
