import parse from 'html-react-parser';
import moment from 'moment';

export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};
export const formatDate = (date: Date) =>
  new Intl.DateTimeFormat('pt-BR', dateOptions).format(date).replace(/\.? de /g, ' ');

export const formatMonthAndYearDate = (date: string) => {
  const monthAndYear = new Date(date).toLocaleString('pt-br', { month: 'long', year: 'numeric' });

  return `${monthAndYear.charAt(0).toLocaleUpperCase()}${monthAndYear.slice(1).replace('de', '')}`;
};

export const formatToCurrentMonthAndYearDate = (date: string) => {
  const [year, month] = date.split('-').map(Number);
  const newDate = new Date(year, month - 1);

  const monthAndYear = newDate.toLocaleString('pt-br', { month: 'long', year: 'numeric' });

  return `${monthAndYear.charAt(0).toLocaleUpperCase()}${monthAndYear.slice(1).replace('de', '')}`;
};

export const formatDateStr = (date: string) => {
  const fullDate = new Date(date).toLocaleString('pt-br', { month: '2-digit', year: 'numeric', day: '2-digit' });

  return fullDate.replaceAll('/', '');
};

export const formatStrToDateUSFormat = (dateStr: string) => {
  const year = dateStr.substring(4);
  const month = dateStr.substring(2, 4);
  const day = dateStr.substring(0, 2);
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};
export const formatNumber = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options ? options : { style: 'decimal', useGrouping: true, maximumFractionDigits: 2 };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCurrency = (num: number, options?: Intl.NumberFormatOptions): string => {
  const opts = options ? options : { style: 'currency', currency: 'BRL', useGrouping: true };
  return new Intl.NumberFormat('pt-BR', opts).format(num);
};

export const formatCNPJ = (cnpj?: string): string => {
  const formatted: string[] = [];
  if (cnpj) {
    [...cnpj].forEach((char, index) => {
      formatted.push(char);
      if (index === 1 || index == 4) formatted.push('.');
      if (index === 7) formatted.push('/');
      if (index === 11) formatted.push('-');
    });
  }

  return formatted.join('');
};

export const formatCPF = (cpf?: string): string => {
  const formatted: string[] = [];
  if (cpf) {
    [...cpf].forEach((char, index) => {
      formatted.push(char);
      if (index === 2 || index == 5) formatted.push('.');
      if (index === 8) formatted.push('-');
    });
  }

  return formatted.join('');
};

export const getFileNameFromPath = (filePath: string) => {
  const filePathSplited = filePath.split('/');
  return filePathSplited[filePathSplited.length - 1];
};

export const upperFirstLetter = (str: string) => str[0]?.toUpperCase() + str.slice(1).toLowerCase();

export const formatFilePath = (filePath: string) => {
  const currentItem = filePath.split('/')[0];
  if (currentItem.includes('.')) {
    return currentItem;
  }
  return upperFirstLetter(currentItem.replaceAll('_', ' '));
};

export const string2HTMLParser = (stringToParse: string) => {
  return parse(stringToParse.replace(/(?:\r\n|\r|\n)/g, '<br>'));
};

export const formatDecimal = (value: number, decimalPlace: 2 | 3): string => {
  return value || value === 0 ? value.toFixed(decimalPlace).replace('.', ',') : '';
};

export const formatAsPercent = (value: number): string => {
  const percentString = value.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 });

  return percentString.includes(',00') ? percentString.replace(',00', '') : percentString;
};

export const formatAsMWh = (value: number): string => {
  return `${String(value.toFixed(3)).replace('.', ',')} MWh`;
};

export const formatNumberToStringWithSuffix = (value: number, suffix: string, decimalPlaces = 3): string => {
  return `${formatNumber(Number(value.toFixed(decimalPlaces)))} ${suffix}`;
};

export const formatUuid = (uuidStr: string): string => {
  return uuidStr.slice(-6).toUpperCase();
};

export const getHourByDateTime = (dateTime: string): string => {
  return (moment(dateTime).hour() + 1).toString();
};

export const FinancialEventTypeTranslated = {
  ASSOCIATIVE_CONTRIBUTION: 'Contribuição Associativa',
  FINANCIAL_GUARANTEE: 'Garantia Financeira',
  RESERVE_ENERGY_CHARGE: 'EER',
  FINANCIAL_SETTLEMENT: 'Liquidação Financeira',
  POWER_CHARGE_CAPACITY_RESERVE: 'ERCAP',
};
