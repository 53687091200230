import React from 'react';
import get from 'lodash.get';
import { NumericInput } from '@clarke-energia/foton';
import { Controller, FieldPath, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { NumberFormatProps } from 'react-number-format';

export interface NumericFormFieldProps<T extends FieldValues> {
  field: Path<T>;
  id: string;
  label: string;
  formatProps: Pick<
    NumberFormatProps,
    | 'placeholder'
    | 'prefix'
    | 'suffix'
    | 'allowNegative'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'format'
    | 'mask'
    | 'isNumericString'
  >;
  options?: RegisterOptions<T, FieldPath<T>>;
  className?: string;
  tooltipContent?: string;
}
export function NumericFormField<T extends FieldValues>({
  field,
  id,
  label,
  formatProps: {
    placeholder,
    prefix,
    suffix,
    format,
    mask,
    allowNegative = false,
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = true,
    isNumericString,
  },
  options,
  tooltipContent,
}: NumericFormFieldProps<T>) {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>();

  const error = get(errors, field);

  return (
    <Controller
      control={control}
      name={field}
      rules={options}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <NumericInput
          id={id}
          name={name}
          label={label}
          disabled={options?.disabled}
          value={isNaN(value) || value === null || value === undefined ? '' : value.toString()}
          tooltipContent={tooltipContent}
          formatProps={{
            placeholder,
            thousandSeparator: thousandSeparator,
            decimalSeparator: decimalSeparator,
            decimalScale: decimalScale,
            fixedDecimalScale: fixedDecimalScale,
            allowNegative: allowNegative,
            prefix: prefix,
            suffix: suffix,
            format: format,
            mask: mask,
          }}
          required={(options?.required as boolean) || false}
          error={error?.message as string | undefined}
          onBlur={onBlur}
          onChange={{
            valueArgType: isNumericString ? 'string' : 'number',
            callback: (value) => {
              onChange(value);
            },
          }}
        />
      )}
    />
  );
}
