import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ptbr from 'dayjs/locale/pt-br';

import { monthsInPortuguese } from './constants/common';
import { DiffParam } from './types';

dayjs.extend(customParseFormat);

export type MonthsInPortugueseRangeForYear = Record<string, { label: string; month: number }[]>;

/**
 * PT-BR configured dayjs module instance.
 * */
export const $dayjs = dayjs;

export const parseStringToDate = (str: string): Date => dayjs(str).toDate();

export const formatDate = (date: Date, template = 'DD MMM YYYY') => dayjs(date).locale(ptbr).format(template);

export const englishFormatDateFromString = (
  dateStr: string,
  inputFormat: string,
  outputFormat = 'DD/MM/YYYY, HH:mm',
): string => dayjs(dateStr, inputFormat).format(outputFormat);

export const formatDateFromString = (dateStr: string, inputFormat: string, outputFormat = 'DD/MM/YYYY, HH:mm') =>
  dayjs(dateStr, inputFormat, 'pt-br').format(outputFormat);

export const getDiffFromDateStr = (firstDate: string, secondDate: string, diffParam: DiffParam): number => {
  const date1 = dayjs(firstDate);
  const date2 = dayjs(secondDate);

  return date1.diff(date2, diffParam);
};

export const getMonthRange = (
  startDate: string,
  endDate: string,
  template = 'YYYY-MM-DD',
): MonthsInPortugueseRangeForYear => {
  const start = dayjs(startDate, template).startOf('month');
  const end = dayjs(endDate, template).startOf('month');

  const diff = end.diff(start, 'month') + 1;

  const monthsGroupedByYear: Record<string, { label: string; month: number }[]> = {};

  Array.from({ length: diff }, (_, i) => {
    const currentMonth = start.add(i, 'month');
    const monthNumber = currentMonth.month() + 1;
    const year = currentMonth.year();

    const monthLabel = monthsInPortuguese.find((m) => m.month === monthNumber)?.label ?? '';

    if (!monthsGroupedByYear[year]) {
      monthsGroupedByYear[year] = [];
    }

    monthsGroupedByYear[year].push({ label: monthLabel, month: monthNumber });
  });

  return monthsGroupedByYear;
};
