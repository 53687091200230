import React from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { MonthsInPortugueseRangeForYear } from '@utils/dayjs';

import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { NumericFormField } from '@components/atoms/form';
import VolumePerMonthsFields from './volume-sazonality';

interface ContractedVolumeSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
  isFixedContractedVolume: 'false' | 'true' | null;
  setValue: UseFormSetValue<FieldValues>;
  months: MonthsInPortugueseRangeForYear;
}

const ContractedVolumeFields = ({
  yearsFields,
  startYear,
  isFixedContractedVolume,
  setValue,
  months,
}: ContractedVolumeSectionProps): JSX.Element => {
  const propsVolume = {
    placeholder: 'MWm',
    suffix: ' MWm',
    allowNegative: false,
    fixedDecimalScale: true,
    decimalScale: 3,
  };

  return (
    <>
      {isFixedContractedVolume === 'true' ? (
        <NumericFormField<CreateEnergyContractPayload>
          field={`annualVolumes.${0}.volumeAmount`}
          id="fixedContractedVolume"
          label="Volume contratado"
          formatProps={propsVolume}
          options={{
            required: { value: true, message: 'Campo é obrigatório' },
            onChange: (e) => {
              const value = e.target.value;

              yearsFields.forEach((_, yearIdx) => {
                setValue(`annualVolumes.${yearIdx}.volumeAmount`, value);
                setValue(`annualVolumes.${yearIdx}.year`, startYear + yearIdx);
                setValue(`annualVolumes.${yearIdx}.month`, 1);
              });
            },
          }}
        />
      ) : (
        <VolumePerMonthsFields startYear={startYear} yearsFields={yearsFields} months={months} />
      )}
    </>
  );
};

export default ContractedVolumeFields;
