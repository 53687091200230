import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { MonthsInPortugueseRangeForYear } from '@utils/dayjs';

import { NumericFormField } from '@components/atoms/form';
import { Tabs, TabsProps } from '@clarke-energia/foton';
import VariationPerYear from './volume-variation-per-year';

interface ContractedVolumeSectionProps {
  yearsFields: Record<'id', string>[];
  startYear: number;
  months: MonthsInPortugueseRangeForYear;
}

const VolumePerMonthsFields = ({ startYear, yearsFields, months }: ContractedVolumeSectionProps): JSX.Element => {
  const { setValue, watch } = useFormContext<CreateEnergyContractPayload>();
  const hasSazonality = watch('hasSazonality');
  const volumes = watch('contractedVolumePerYear') || [];

  useEffect(() => {
    const expectedSize = yearsFields.reduce((acc, _, yearIdx) => acc + months[startYear + yearIdx]?.length, 0);

    if (volumes.length < expectedSize) {
      const initializedValues = yearsFields.flatMap((_, yearIdx) =>
        months[startYear + yearIdx].map(({ month }) => ({
          year: startYear + yearIdx,
          month,
          volumeAmount: 0,
        })),
      );

      setValue('contractedVolumePerYear', initializedValues, { shouldDirty: false, shouldValidate: false });
    }
  }, [setValue, volumes, yearsFields, months, startYear]);

  const annualSum = (year: number) => {
    return volumes
      .filter(({ year: entryYear, volumeAmount }) => entryYear === year && volumeAmount !== undefined)
      .map(({ volumeAmount }) => Number(volumeAmount) || 0)
      .reduce((acc, cur) => acc + cur, 0);
  };

  const tabsYears = useMemo(() => {
    return yearsFields.map((_, yearIdx) => {
      const year = startYear + yearIdx;

      const baseIndex = yearsFields
        .slice(0, yearIdx)
        .reduce((acc, _, index) => acc + months[startYear + index]?.length, 0);

      return {
        label: `${year}`,
        children: (
          <div>
            <VariationPerYear
              yearIdx={yearIdx}
              year={year}
              hasSazonalityValue={hasSazonality || false}
              setValue={setValue}
            />
            {hasSazonality && Object.keys(months).length && (
              <div className="grid grid-cols-3 gap-6">
                {months[year].map(({ label, month }, idx) => {
                  const monthIdx = baseIndex + idx;

                  return (
                    <NumericFormField<CreateEnergyContractPayload>
                      key={`month-field-${monthIdx}`}
                      field={`contractedVolumePerYear.${monthIdx}.volumeAmount`}
                      id={`contractedVolumePerYear-${monthIdx}-volumeAmount`}
                      label={label}
                      formatProps={{
                        placeholder: 'MWm',
                        suffix: ' MWm',
                        allowNegative: false,
                        fixedDecimalScale: true,
                        decimalScale: 3,
                      }}
                      options={{
                        required: { value: true, message: 'Campo é obrigatório' },
                        onChange: (e) => {
                          setValue(`contractedVolumePerYear.${monthIdx}.volumeAmount`, Number(e.target.value) || 0);
                          setValue(`contractedVolumePerYear.${monthIdx}.year`, year);
                          setValue(`contractedVolumePerYear.${monthIdx}.month`, month);

                          const annualSumVol = annualSum(year);
                          setValue(`annualVolumes.${yearIdx}.volumeAmount`, annualSumVol);
                        },
                      }}
                    />
                  );
                })}
              </div>
            )}
          </div>
        ) as unknown as Element,
      };
    });
  }, [yearsFields, months, hasSazonality]);

  return (
    <div className="col-span-3 mt-6 lg:mt-0">
      <Tabs
        tabs={tabsYears as unknown as TabsProps['tabs']}
        onClick={() => {
          if (hasSazonality) setValue('hasSazonality', true);
        }}
      />
    </div>
  );
};

export default VolumePerMonthsFields;
