import React from 'react';

import { FormSection } from '@components/atoms/form';
import { FileField } from '@components/atoms/form/file/file-input';
import { Attachments, ClarkeContractAttachmentsPayload } from '@contexts/clarke-contract/types';
import { useClarkeContract } from '@hooks/use-clarke-contract';

interface AttachmentsSectionProps {
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  storedFiles?: Array<Attachments>;
}

const AttachmentsSection = ({ setUploadedFiles, storedFiles }: AttachmentsSectionProps): JSX.Element => {
  const { deleteAttachmentClarkeContractHandler, loadingFormActions } = useClarkeContract();
  return (
    <>
      <FormSection title="Documentos" subtitle="Vincule o Contrato de Gestão assinado." />
      <div className="col-end-5 gap-x-6 gap-y-6 w-full lg:grid lg:grid-cols-2 lg:col-start-2 xl:grid-cols-3">
        <div className="col-span-2">
          <FileField<ClarkeContractAttachmentsPayload>
            field="clarkeContractAttachments"
            id="clarkeContractAttachments"
            label={'Anexar documentos'}
            description={'Envie documentos referente ao contrato de gestaão desse grupo comercial. PDF de até 5MB'}
            maximumFileSizeInMB={5}
            allowedFileTypes={['.pdf']}
            onChangeFiles={(files) => setUploadedFiles(files as File[])}
            storedFiles={storedFiles}
            handleRemoveStoredFile={deleteAttachmentClarkeContractHandler}
            loading={loadingFormActions}
            multipleFiles
            options={{ required: { value: !storedFiles, message: 'Anexe ao menos um documento' } }}
          />
        </div>
      </div>
    </>
  );
};

export default AttachmentsSection;
